import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {OPEN_INVOICES_LIST, openInvoicesList} from "../Actions/openInvoicesList";
import {getAxiosConfig, handleValidation, invalidateInvoiceData, invalidateInvoiceNumberSummary} from "./tenantsSaga";
import {busyCall} from "./businessSaga";
import axios from "../../exios";
import {getApiUrl, getPurchaserUrl} from "./utils";
import {resetCurrentPurchaserWindow} from "../Actions/resetCurrentPurchaserWindow";
import {OPEN_ADD_INVOICE_FORM} from "../Actions/openAddInvoiceForm";
import {invalidateProducts} from "./productListSaga";
import {ADD_INVOICE} from "../Actions/addInvoice";
import {PRINT_INVOICE} from "../Actions/printInvoice";
import {OPEN_INVOICE_PREVIEW, openInvoicePreview} from "../Actions/openInvoicePreview";
import {OPEN_EDIT_INVOICE_FORM} from "../Actions/openEditInvoiceForm";
import {UPDATE_INVOICE} from "../Actions/updateInvoice";
import {downloadFile} from "./downloadFile";
import {DELETE_INVOICE} from "../Actions/deleteInvoice";
import {SET_INVOICE_PAID_VALUE} from "../Actions/setInvoicePaidValue";
import {OPEN_UNPAID_INVOICES_SUMMARY, openUnpaidInvoicesSummary} from "../Actions/openUnpaidInvoicesSummary";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {SET_INVOICES_AS_PAID} from "../Actions/setInvoicesAsPaid";
import {SEND_INVOICE_BY_EMAIL} from "../Actions/sendInvoiceByEmail";
import {blinkNotification} from "../Actions/blinkNotification";
import {REQUEST_INVOICE_PROPOSED_NUMBER} from "../Actions/requestInvoiceProposedNumber";
import formats from "../../formats";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* handleOpenInvoicesList({tenantId, purchaserId, year}) {
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {invoicesList: {loading: true, year }}));

    const config = yield getConfig(tenantId);
    const actualConfig = {
        ...config,
        params: {
            year
        }
    }
    yield invalidateProducts(tenantId);
    yield invalidateInvoiceData(tenantId);

    const {data: {items, yearsActive}} = yield busyCall(axios.get, getPurchaserUrl('/invoices', tenantId, purchaserId), actualConfig);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {invoicesList: {items, yearsActive, year}}));
}

function* handleOpenAddInvoiceForm({tenantId, purchaserId}) {
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId));

    const config = yield getConfig(tenantId);
    yield invalidateProducts(tenantId);
    yield invalidateInvoiceNumberSummary(tenantId);

    const {data: provisionsToBook} = yield busyCall(axios.get, getPurchaserUrl('/invoices/provisions-to-book', tenantId, purchaserId), config);

    let cache = yield select(o => o.cache[tenantId]);
    let invoiceNumberSummary = cache?.invoiceNumberSummary?.data;
    while (!invoiceNumberSummary) {
        yield delay(500);
        cache = yield select(o => o.cache[tenantId]);
        invoiceNumberSummary = cache?.invoiceNumberSummary?.data;
    }

    const invoiceData = {
        date: (invoiceNumberSummary?.lastDate),
        number: '',
        payday: (invoiceNumberSummary?.lastPayday) || '14 dni',
        positions: []
    }

    const addInvoiceForm = {
        invoiceData,
        provisionsToBook,
        proposedNumber: invoiceNumberSummary?.nextNumber
    }

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {addInvoiceForm}));
}

function* handleAddInvoice({tenantId, purchaserId, invoiceData}) {
    const oldInvoiceForm = yield select(o => o.currentWindow.addInvoiceForm);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {addInvoiceForm: {...oldInvoiceForm, saving: true}}));
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.post, getPurchaserUrl('/invoices', tenantId, purchaserId), invoiceData, config);
    const {errors} = yield call(handleValidation, response);

    if (!!errors) {
        const addInvoiceForm = {
            ...oldInvoiceForm,
            errors
        };

        yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {addInvoiceForm}));
    } else {
        yield put(openInvoicesList(tenantId, purchaserId));
    }
}

function* handleOpenEditInvoiceForm({tenantId, purchaserId, invoiceId}) {
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId));
    yield invalidateProducts(tenantId);
    const config = yield getConfig(tenantId);
    const [{data: invoiceData}, {data: provisionsToBook}] = yield all([
        busyCall(axios.get, getPurchaserUrl(`/invoices/${invoiceId}`, tenantId, purchaserId), config),
        busyCall(axios.get, getPurchaserUrl(`/invoices/provisions-to-book?invoiceId=${invoiceId}`, tenantId, purchaserId), config)
    ]);

    const editInvoiceForm = {
        invoiceId,
        invoiceData,
        provisionsToBook,
        proposedNumber: invoiceData.number
    }

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editInvoiceForm}));
}

function* handleUpdateInvoice({tenantId, purchaserId, invoiceId, invoiceData}) {
    const oldInvoiceForm = yield select(o => o.currentWindow.editInvoiceForm);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editInvoiceForm: {...oldInvoiceForm, saving: true}}));

    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.put, getPurchaserUrl(`/invoices/${invoiceId}`, tenantId, purchaserId), invoiceData, config);
    const {errors} = yield call(handleValidation, response);

    if (!!errors) {
        const editInvoiceForm = {
            ...oldInvoiceForm,
            errors
        };

        yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editInvoiceForm}));
    } else {
        yield put(openInvoicesList(tenantId, purchaserId));
    }
}

function* handleDeleteInvoice({tenantId, purchaserId, invoiceId}) {
    const config = yield getConfig(tenantId);
    yield busyCall(axios.delete, getPurchaserUrl(`/invoices/${invoiceId}`, tenantId, purchaserId), config);
    yield put(openInvoicesList(tenantId, purchaserId));
}

function* handlePrintInvoice({tenantId, purchaserId, invoiceId, invoiceNumber}) {
    yield downloadFile(
        tenantId,
        getPurchaserUrl(`/invoices/${invoiceId}/printouts/original`, tenantId, purchaserId),
        `Faktura VAT ${invoiceNumber}.pdf`);
}

function* handleOpenInvoicePreview({tenantId, purchaserId, invoiceId}) {
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {invoiceDetails: {loading: true, invoiceId}}));

    yield invalidateInvoiceData(tenantId);

    const config = yield getConfig(tenantId);
    const [{data: details}, {data: invoice}] = yield all([
        busyCall(axios.get, getPurchaserUrl(`/invoices/${invoiceId}/details`, tenantId, purchaserId), config),
        busyCall(axios.get, getPurchaserUrl(`/invoices/${invoiceId}`, tenantId, purchaserId), config)
    ]);

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {invoiceDetails: {details, invoice, invoiceId}}));
}

function* handleSetInvoicePaidValue({tenantId, purchaserId, invoiceId, paidValue}) {
    const config = yield getConfig(tenantId);

    yield busyCall(axios.put, getPurchaserUrl(`/invoices/${invoiceId}/paid-value`, tenantId, purchaserId), {paidValue}, config);

    const currentWindow = yield select(o => o.currentWindow);

    if (currentWindow.invoicesList) {
        yield put(openInvoicesList(tenantId, purchaserId));
    } else if (currentWindow.invoiceDetails) {
        yield put(openInvoicePreview(tenantId, purchaserId, invoiceId));
    } if (currentWindow.unpaidInvoicesList) {
        yield put(openUnpaidInvoicesSummary(tenantId));
    }
}

function* handleOpenUnpaidInvoicesSummary({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, { unpaidInvoicesList: { loading: true } }));

    const config = yield getConfig(tenantId);

    const {data} = yield busyCall(axios.get, getApiUrl(`/reports/invoices/unpaid`, tenantId), config);

    yield put(resetCurrentTenantWindow(tenantId, { unpaidInvoicesList: { data } }));
}

function* handleSetInvoicesAsPaid({tenantId, invoicesIds}) {
    yield put(resetCurrentTenantWindow(tenantId, { unpaidInvoicesList: { loading: true } }));

    const config = yield getConfig(tenantId);

    yield busyCall(axios.put, getApiUrl('/invoices', tenantId), {invoicesIds}, config);

    yield put(openUnpaidInvoicesSummary(tenantId));
}

function* handleSendInvoiceByEmail({tenantId, purchaserId, invoiceId, subject, message}) {
    const config = yield getConfig(tenantId);

    yield busyCall(axios.post, getPurchaserUrl(`/invoices/${invoiceId}/email`, tenantId, purchaserId), {subject, message}, config);

    yield put(blinkNotification("Wiadomość email została wysłana."));
}

function* handleRequestInvoiceProposedNumber({tenantId, purchaserId, date}) {
    const config = yield getConfig(tenantId);
    try {
        const {data} = yield busyCall(axios.get, getApiUrl(`/reports/invoices/next-number/${formats.jsonDate(date)}`, tenantId), config);

        const addInvoiceForm = yield select(o => o.currentWindow.addInvoiceForm);
        if (addInvoiceForm) {
            yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {
                addInvoiceForm: {
                    ...addInvoiceForm,
                    proposedNumber: data
                }
            }));
        }
    } catch {
        console.log('Failed to request invoice proposed number but, it is not a big deal.');
    }
}

export function* invoicesSaga(){
    yield all([
        takeLatest(OPEN_INVOICES_LIST, handleOpenInvoicesList),
        takeLatest(OPEN_ADD_INVOICE_FORM, handleOpenAddInvoiceForm),
        takeLatest(ADD_INVOICE, handleAddInvoice),
        takeLatest(PRINT_INVOICE, handlePrintInvoice),
        takeLatest(OPEN_INVOICE_PREVIEW, handleOpenInvoicePreview),
        takeLatest(OPEN_EDIT_INVOICE_FORM, handleOpenEditInvoiceForm),
        takeLatest(UPDATE_INVOICE, handleUpdateInvoice),
        takeLatest(DELETE_INVOICE, handleDeleteInvoice),
        takeLatest(SET_INVOICE_PAID_VALUE, handleSetInvoicePaidValue),
        takeLatest(OPEN_UNPAID_INVOICES_SUMMARY, handleOpenUnpaidInvoicesSummary),
        takeLatest(SET_INVOICES_AS_PAID, handleSetInvoicesAsPaid),
        takeLatest(SEND_INVOICE_BY_EMAIL, handleSendInvoiceByEmail),
        takeLatest(REQUEST_INVOICE_PROPOSED_NUMBER, handleRequestInvoiceProposedNumber)
    ])
}