import {useSelector} from "react-redux";
import {useMemo} from "react";

export function getCurrentTenantId(state) {
    const currentWindow = state.currentWindow;
    if (currentWindow
        && currentWindow.currentTenant) {
        return currentWindow.currentTenant.id;
    }
    return undefined;
}

export function useCacheData(key) {
    const tenantId = useSelector(getCurrentTenantId);
    const cache = useSelector(o => o.cache[tenantId]);
    const entry = cache?.[key];
    return entry?.data;
}

export function useFeatureFlags() {
    return useCacheData('featureFlags');
}

export function useVisibleProducts() {
    const allProducts = useCacheData('products');
    return useMemo(() => allProducts?.filter(p => p.visible), [allProducts]);
}

export function useAnyProductExists() {
    const allProducts = useCacheData('products');
    return allProducts === undefined ? undefined : allProducts.length > 0;
}

export function getCurrentProviderId(state) {
    const currentWindow = state.currentWindow;
    if (currentWindow
        && currentWindow.currentProvider) {
        return currentWindow.currentProvider.id;
    }
    return undefined;
}

export const useCurrentProvider = () => {
    const providers = useCacheData('providers');
    const currentProviderId = useSelector(getCurrentProviderId);
    const currentProvider = providers?.find(p => p.id === currentProviderId);
    return currentProvider ?? { id: currentProviderId, loading: true };
}

export function getCurrentPurchaserId(state) {
    const currentWindow = state.currentWindow;
    if (currentWindow
        && currentWindow.currentPurchaser) {
        return currentWindow.currentPurchaser.id;
    }
    return undefined;
}

export function useCurrentPurchaser() {
    const purchasers = useCacheData('purchasers');
    const currentPurchaserId = useSelector(getCurrentPurchaserId);
    const currentPurchaser = purchasers?.find(p => p.id === currentPurchaserId);
    return currentPurchaser ?? { id: currentPurchaserId, loading: true };
}

export function getCurrentPurchaser(state) {
    return state.currentWindow?.currentPurchaser;
}

export function getTenantsAccessToken(tenantId, audience) {
    return state => {
        const tenantsTokens = state.tenantsAccessTokens[tenantId];
        return  tenantsTokens ? tenantsTokens[audience] : undefined;
    };
}

function getPermissions(state) {
    const tenantId = getCurrentTenantId(state);
    if (!tenantId) return [];

    const accessToken = getTenantsAccessToken(tenantId, 'https://api.skupomat.pl')(state);

    return accessToken?.permissions ?? [];
}

export function usePermissions() {
    const permissions = useSelector(getPermissions);
    const globalAccessToken = useSelector(o => o.accessTokens);

    return {
        hasPermission: permissionCode => permissions.includes(permissionCode) || permissions.includes("all"),
        isAdmin: () => {
            const audience = 'https://tenants.skupomat.pl';
            const tenantAccessToken = globalAccessToken[audience];
            const bodyBase64 = tenantAccessToken.split('.')[1];
            const bodyObject = JSON.parse(atob(bodyBase64));

            return bodyObject.permissions.includes("read:all-tenants");
        }
    }
}

export function isExpired(state) {
    const tenantId = getCurrentTenantId(state);
    if (!tenantId) return true;

    const accessToken = getTenantsAccessToken(tenantId, 'https://api.skupomat.pl')(state);
    if (!accessToken) {
        return true;
    }
    return !accessToken.isActive;
}