import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {busyWrapper, busyCall} from "./businessSaga";
import {OPEN_DEALS_LIST, openDealsList} from "../Actions/openDealsList";
import {OPEN_ADD_DEAL_FORM} from "../Actions/openAddDealForm";
import {ADD_DEAL} from "../Actions/addDeal";
import {resetCurrentProviderWindow} from "../Actions/resetCurrentProviderWindow";
import {getAxiosConfig, handleValidation} from "./tenantsSaga";
import {addProviderEntity, getApiUrl, getProviderUrl} from "./utils";
import axios from "../../exios";
import {invalidateProducts} from "./productListSaga";
import moment from "moment";
import {DELETE_DEAL} from "../Actions/deleteDeal";
import {OPEN_EDIT_DEAL_FORM} from "../Actions/openEditDealForm";
import {UPDATE_DEAL} from "../Actions/updateDeal";
import {EXPORT_DEAL_LIST} from "../Actions/exportDealList";
import {downloadFile} from "./downloadFile";
import {invalidateContainers} from "./containersSaga";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* handleOpenDealsList({tenantId, providerId, year}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {deals: { loading: true }}));
    const config = yield getConfig(tenantId);
    const actualConfig = {
        ...config,
        params: {year}
    };
    yield invalidateProducts(tenantId);
    const {data: {items, yearsActive}} = yield busyCall(axios.get, getProviderUrl('/deals',tenantId, providerId), actualConfig);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {deals: { items, yearsActive, year }}));
}

function* handleOpenAddDealForm({tenantId, providerId}){
    yield put(resetCurrentProviderWindow(tenantId, providerId));
    const config = yield getConfig(tenantId);

    yield invalidateProducts(tenantId);
    yield invalidateContainers(tenantId);

    const {data: mostRecentDeal} = yield busyCall(axios.get, getApiUrl('/reports/deals/most-recent',tenantId), config);
    const date = (mostRecentDeal && mostRecentDeal.date) || moment().startOf('day').toJSON();

    yield invalidateProducts(tenantId);

    const formData = {date, positions: [], containerShares: []};

    yield put(resetCurrentProviderWindow(tenantId, providerId, { addDealForm: { formData }}));
}

function* handleAddDeal({tenantId, providerId, dealData}) {
    yield addProviderEntity(
        tenantId,
        providerId,
        dealData,
        'addDealForm',
        '/deals',
        () => openDealsList(tenantId, providerId));
}

function* handleDeleteDeal({tenantId, providerId, dealId}) {
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.delete, getProviderUrl(`/deals/${dealId}`, tenantId, providerId), config);
    const {errors} = yield call(handleValidation, response);
    if (!!errors) {
        const oldDeals = yield select(o => o.currentWindow.deals);
        yield put(resetCurrentProviderWindow(tenantId, providerId, {deals: { ...oldDeals, errors }}));
    } else {
        yield put(openDealsList(tenantId, providerId));
    }
}

function* handleOpenEditDealForm({tenantId, providerId, dealId}) {
    const config = yield getConfig(tenantId);

    yield invalidateProducts(tenantId);
    yield invalidateContainers(tenantId);

    yield put(resetCurrentProviderWindow(tenantId, providerId, {}));
    const {data} = yield busyCall(axios.get, getProviderUrl(`/deals/${dealId}`, tenantId, providerId), config);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {editDealForm: {dealId, formData: data}}));
}

function* handleUpdateDeal({tenantId, providerId, dealId, dealData}) {
    const oldDeals = yield select(o => o.currentWindow.editDealForm);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {editDealForm: { ...oldDeals, saving: true }}));
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.put, getProviderUrl(`/deals/${dealId}`, tenantId, providerId), dealData, config);
    const {errors} = yield call(handleValidation, response);
    if (!!errors) {
        yield put(resetCurrentProviderWindow(tenantId, providerId, {editDealForm: { ...oldDeals, errors }}));
    } else {
        yield put(openDealsList(tenantId, providerId));
    }
}

function* handleExportDealList({tenantId, providerId}) {
    const oldDeals = yield select(o => o.currentWindow.deals);
    const exportingDeals = {
        ...oldDeals,
        isExportingToExcel: true
    };
    yield put(resetCurrentProviderWindow(tenantId, providerId, {deals: exportingDeals}));

    const extraConfig = {
        headers: {
            Accept: "application/vnd.ms-excel"
        }
    };

    const providersCache = yield select(o => o.cache[tenantId].providers.data);
    const currentProvider = providersCache.find(p => p.id === providerId);

    yield downloadFile(
        tenantId,
        getProviderUrl('/deals', tenantId, providerId),
        `Odbiory ${currentProvider?.name}.xlsx`,
        extraConfig);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {deals: oldDeals}))
}

export function* dealsSaga() {
    yield all([
        takeLatest(OPEN_DEALS_LIST, busyWrapper(handleOpenDealsList)),
        takeLatest(OPEN_ADD_DEAL_FORM, handleOpenAddDealForm),
        takeLatest(ADD_DEAL, busyWrapper(handleAddDeal)),
        takeLatest(DELETE_DEAL, handleDeleteDeal),
        takeLatest(OPEN_EDIT_DEAL_FORM, handleOpenEditDealForm),
        takeLatest(UPDATE_DEAL, handleUpdateDeal),
        takeLatest(EXPORT_DEAL_LIST, handleExportDealList)
    ]);
}