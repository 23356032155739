import axios from '../../exios';
import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {ADD_TENANT} from '../Actions/addTenant';
import {INVITE_USER} from "../Actions/inviteUser";
import {OPEN_ADD_TENANT_FORM} from "../Actions/openAddTenantForm";
import {OPEN_TENANTS_LIST} from '../Actions/openTenantsList';
import {OPEN_TENANT_PREVIEW, openTenantPreview} from '../Actions/openTenantsPreview';
import {OPEN_EDIT_TENANT_FORM} from "../Actions/openEditTenantForm";
import {UPDATE_TENANT} from "../Actions/updateTenant";
import {RESET_CURRENT_TENANT_WINDOW, resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {getAxiosConfig as getRootAxiosConfig} from './authSaga';
import {resetCurrentWindow} from "../Actions/resetCurrentWindow";
import {setTenantAccessToken} from "../Actions/setTeanantAccessToken";
import {busyCall, busyWrapper} from "./businessSaga";
import {getApiUrl} from "./utils";
import moment from "moment";
import {getTenantsAccessToken} from "../stateUtils";
import {LOGGED_OUT, SET_AUTH_STATUS} from "../Actions/setAuthStatus";
import {clearTenantAccessTokens} from "../Actions/clearTenantAccessTokens";
import {ADD_FIRST_TENANT} from "../Actions/addFirstTenant";
import {SHOW_TRANSPORT_PRODUCT_DAILY_DETAILS} from "../Actions/showTransportProductDailyDetails";
import {HIDE_TRANSPORT_PRODUCT_DAILY_DETAILS} from "../Actions/hideTransportProductDailyDetails";
import formats from "../../formats";
import {OPEN_TENANT_FEATURE_FLAGS_FORM} from "../Actions/openTenantFeatureFlags";
import {SAVE_TENANT_FEATURE_FLAGS} from "../Actions/saveTenantFeatureFlagsForm";
import {SHOW_INVOICE_PRODUCT_DAILY_DETAILS} from "../Actions/showInvoiceProductDailyDetails";
import {HIDE_INVOICE_PRODUCT_DAILY_DETAILS} from "../Actions/hideInvoiceProductDailyDetails";
import {loadCacheEntry} from "../Actions/loadCacheEntry";

export const getUrl = (path)=> process.env.REACT_APP_TENANT_URL + path;

const THIRTY_SECONDS = moment.duration(30, 'seconds');
const getExpiration = (result) => (moment().add(moment.duration(result.expiresIn, 'milliseconds')).toJSON());
const expired = (expiration) => ((moment().subtract(THIRTY_SECONDS).isAfter(moment(expiration))));

function getConfig() {
    return getRootAxiosConfig('https://tenants.skupomat.pl');
}

function getApiConfig(tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* callAddTenant(tenantData) {
    const headTenantData = {
        name: tenantData.name
    };

    const axiosConfig = yield getConfig();
    const {data: tenantId} = yield call(axios.post, getUrl('/tenants/'), headTenantData, axiosConfig);

    return tenantId;
}

function* handleAddTenant({tenantData}) {
    const addTenantForm = yield select(state => state.currentWindow.addTenantForm);
    yield put(resetCurrentWindow({addTenantForm:{...addTenantForm, tenantData, saving: true}}));

    const tenantId = yield callAddTenant(tenantData);

    const result = yield updateInvoiceData(tenantData, tenantId);
    const {errors} = yield call(handleValidation, result);

    if (errors) {
        yield put(resetCurrentTenantWindow(tenantId, {editTenantForm:{tenantData, errors}}));
        return;
    }

    yield put(openTenantPreview(tenantId));
}

function* handleAddFirstTenant({tenantData}) {
    yield put(resetCurrentWindow({addFirstTenantForm:{saving: true}}));
    const tenantId = yield callAddTenant(tenantData);

    // Data are prepared to be properly saved
    yield updateInvoiceData(tenantData, tenantId);

    yield put(openTenantPreview(tenantId));
}

function* getTenantList() {
    const axiosConfig = yield getConfig();
    const response = yield busyCall(axios.get, getUrl('/tenants/'), axiosConfig);
    return response.data;
}

function* handleInviteUser() {
    yield put(resetCurrentWindow({}));
    const tenants = yield getTenantList();
    if (tenants.length === 0) {
        yield put(resetCurrentWindow({addFirstTenantForm:{}}));
        return;
    }

    const tenantId = tenants[0].id;
    yield put(openTenantPreview(tenantId));
}

function* handleOpenAddTenantForm() {
    const defaultTenantData = {
        name: '',
        invoiceNumberTemplate: '{numer}/{mm}/{rrrr}',
        rrInvoiceNumberTemplate: '{numer}/{mm}/{rrrr}'
    };
    yield put(resetCurrentWindow({addTenantForm: {tenantData: defaultTenantData}}));
}

function* handleOpenTenantsList() {
    yield put(resetCurrentWindow({tenants: {loading: true}}));
    const tenants = yield getTenantList();
    yield put(resetCurrentWindow({tenants: {data: tenants}}));
}

export function* invalidateInvoiceData(tenantId) {
    yield put(loadCacheEntry(tenantId, 'invoiceData', getApiUrl('/invoice-data', tenantId)));
}

function* invalidateFeatureFlags(tenantId) {
    yield put(loadCacheEntry(tenantId, 'featureFlags', getApiUrl('/feature-flags', tenantId)));
}

function* handleResetCurrentTenantWindow({tenantId, state}) {
    const currentWindow = yield select(state => state.currentWindow);
    let currentTenant;
    if (currentWindow?.currentTenant?.id === tenantId && !currentWindow?.currentTenant?.loading) {
        currentTenant = currentWindow.currentTenant;
    } else {
        yield put(resetCurrentWindow({
            ...state,
            currentTenant: {loading: true, id: tenantId}
        }));

        yield invalidateFeatureFlags(tenantId);
        yield invalidateInvoiceData(tenantId);

        // Hack this delay debounces loading tenant data
        yield delay(100);
        const tenantsConfig = yield getConfig();
        const {data: tenantResponse} = yield busyCall(axios.get, getUrl(`/tenants/${tenantId}`), tenantsConfig);

        currentTenant = {
            ...tenantResponse
        };
    }

    yield put(resetCurrentWindow({
        ...state,
        currentTenant
    }));
}

function* loadDashboardPart(url, tenantId, partName) {
    yield put(loadCacheEntry(tenantId, partName, getApiUrl(url, tenantId)));
}

export function* invalidateInvoiceNumberSummary(tenantId) {
    yield put(loadCacheEntry(tenantId, 'invoiceNumberSummary', getApiUrl('/reports/invoices/number-summary', tenantId)));
}

export function* invalidateRrInvoiceNumberSummary(tenantId) {
    yield put(loadCacheEntry(tenantId, 'rrInvoiceNumberSummary', getApiUrl('/reports/rr-invoices/number-summary', tenantId)));

}

function* handleOpenTenantPreview({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard: { }}));

    yield all
    ([
        loadDashboardPart('/reports/deals/summary', tenantId, 'dealsSummary'),
        loadDashboardPart('/reports/deals/container-share-summary', tenantId, 'dealsContainerShareSummary'),
        loadDashboardPart('/reports/provisions/summary', tenantId, 'provisionsSummary'),
        loadDashboardPart(`/reports/provisions/container-share-summary`, tenantId, 'provisionsContainerShareSummary'),
        loadDashboardPart('/reports/rr-invoices/summary', tenantId, 'rrInvoicesSummary'),
        loadDashboardPart('/reports/rr-invoices/providers-to-book', tenantId, 'providersToBook'),
        loadDashboardPart('/reports/rr-invoices/funds-summary', tenantId, 'fundSummary'),
        loadDashboardPart('/reports/rr-invoices/deals-to-book-product-summary', tenantId, 'dealsToBookProductSummary'),
        loadDashboardPart('/reports/rr-invoices/unpaid-summary', tenantId, 'unpaidRrInvoicesSummary'),
        loadDashboardPart('/reports/invoices/summary', tenantId, 'invoicesSummary'),
        loadDashboardPart('/reports/invoices/purchasers-to-book', tenantId, 'purchasersToBook'),
        loadDashboardPart('/reports/invoices/provisions-to-book-product-summary', tenantId, 'provisionsToBookProductSummary'),
        loadDashboardPart('/reports/invoices/unpaid-summary', tenantId, 'unpaidInvoicesSummary'),
        loadDashboardPart('/reports/invoices/number-summary', tenantId, 'invoiceNumberSummary'),
        loadDashboardPart('/reports/rr-invoices/number-summary', tenantId, 'rrInvoiceNumberSummary')
    ]);
}

function* handleOpenEditTenantForm({tenantId}) {
    yield invalidateInvoiceData(tenantId);

    const tenantsConfig = yield getConfig();
    const {data: tenantResponse} = yield busyCall(axios.get, getUrl(`/tenants/${tenantId}`), tenantsConfig);

    let invoiceDataCache = yield select(o => o.cache[tenantId]?.invoiceData);
    while (!invoiceDataCache) {
        yield delay(500);
        invoiceDataCache = yield select(o => o.cache[tenantId]?.invoiceData);
    }

    const invoiceData = invoiceDataCache.data;

    const tenantData = {
        ...invoiceData,
        name: tenantResponse.name,
        fullName: invoiceData.name
    };

    yield put(resetCurrentTenantWindow(tenantId,{
        editTenantForm: {
            tenantId,
            tenantData
        }
    }));
}

function* handleUpdateTenant({tenantId, tenantData}) {
    const editTenantForm = yield select(state => state.currentWindow.editTenantForm);
    yield put(resetCurrentTenantWindow(tenantId, {editTenantForm:{...editTenantForm, tenantData, saving: true}}));

    const result = yield updateInvoiceData(tenantData, tenantId);
    const {errors} = yield call(handleValidation, result);

    if (errors) {
        yield put(resetCurrentTenantWindow(tenantId, {editTenantForm:{tenantData, errors}}));
        return;
    }

    const headTenantData = {
        name: tenantData.name
    };

    const tenantsConfig = yield getConfig();
    yield busyCall(axios.put, getUrl(`/tenants/${tenantId}`), headTenantData, tenantsConfig);

    // Hack: clear cached tenant data
    yield put(resetCurrentWindow({}));
    yield put(openTenantPreview(tenantId));
}

function* updateInvoiceData(tenantData, tenantId){
    const {
        fullName,
        vatId,
        address,
        accountNo,
        emailAddress,
        invoiceNumberTemplate,
        rrInvoiceNumberTemplate
    } = tenantData;

    const invoiceData = {
        name: fullName,
        vatId,
        address,
        accountNo,
        emailAddress,
        invoiceNumberTemplate,
        rrInvoiceNumberTemplate
    };

    const apiConfig = yield getApiConfig(tenantId);
    return yield busyCall(axios.put, getApiUrl('/invoice-data', tenantId), invoiceData, apiConfig);
}

export function handleValidation({status, data}) {
    if (status === 422) {
        return {
            errors: data
        }
    } else {
        return {
            data
        }
    }
}

const audiencesBeingLoaded = new Set();

export function* getAxiosConfig(tenantId, audience) {
    const buildConfig = (accessToken) =>({
        headers: {'Authorization': 'Bearer ' + accessToken.token},
        validateStatus: status => (status >= 200 && status < 300) || status === 422
    });

    let accessToken = yield select(getTenantsAccessToken(tenantId, audience));

    if (accessToken && !expired(accessToken.expiration)) {
        return buildConfig(accessToken);
    }

    if (audiencesBeingLoaded.has(audience)) {
        while (audiencesBeingLoaded.has(audience)) {
            yield delay(500);
        }
        accessToken = yield select(getTenantsAccessToken(tenantId, audience));
        if (accessToken && !expired(accessToken.expiration)) {
            return buildConfig(accessToken);
        }
    }

    audiencesBeingLoaded.add(audience);

    const axiosConfig = yield getConfig();
    const {data: responseData} = yield call(axios.get, getUrl(`/tenants/${tenantId}/access-token?audience=${audience}`), axiosConfig);

    audiencesBeingLoaded.delete(audience);

    accessToken = {...responseData, expiration: getExpiration(responseData)};
    yield put(setTenantAccessToken(tenantId, audience, accessToken));
    return buildConfig(accessToken);
}

export function* handleSetAuthStatus({authStatus}){
    if (authStatus === LOGGED_OUT) {
        yield put(clearTenantAccessTokens());
    }
}

export function* handleShowTransportProductDailyDetails({tenantId, day, productId}) {
    const oldTenantDashboard = yield select(o => o.currentWindow.tenantDashboard);
    if (!oldTenantDashboard) {
        return;
    }

    const tenantDashboard = {
        ...oldTenantDashboard
    };
    tenantDashboard.transportProductDailyDetails = {loading: true};

    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard}));

    const apiConfig = yield getApiConfig(tenantId);
    const [{data: dealPositionsOfDay}, {data: provisionPositionsOfDay}] = yield all([
        busyCall(
            axios.get,
            getApiUrl(`/reports/deals/positions-of-day/product/${productId}/${formats.jsonDate(day)}`, tenantId),
            apiConfig),
        busyCall(
            axios.get,
            getApiUrl(`/reports/provisions/positions-of-day/product/${productId}/${formats.jsonDate(day)}`, tenantId),
            apiConfig)]);

    const currentWindow2 = yield select(o => o.currentWindow.tenantDashboard);
    if (!currentWindow2) {
        return;
    }

    const tenantDashboard2 = {
        ...oldTenantDashboard
    };
    tenantDashboard2.transportProductDailyDetails = {dealPositionsOfDay, provisionPositionsOfDay};

    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard: tenantDashboard2}));
}

export function* handleHideTransportProductDailyDetails({tenantId}) {
    const oldTenantDashboard = yield select(o => o.currentWindow.tenantDashboard);
    if (!oldTenantDashboard) {
        return;
    }

    const tenantDashboard = {
        ...oldTenantDashboard
    };
    tenantDashboard.transportProductDailyDetails = undefined;

    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard}));
}

export function* handleShowInvoiceProductDailyDetails({tenantId, day, productId}) {
    const oldTenantDashboard = yield select(o => o.currentWindow.tenantDashboard);
    if (!oldTenantDashboard) {
        return;
    }

    const tenantDashboard = {
        ...oldTenantDashboard
    };
    tenantDashboard.transportProductDailyDetails = {loading: true};

    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard}));

    const apiConfig = yield getApiConfig(tenantId);
    const [{data: invoicePositionsOfTheDay}, {data: rrInvoicePositionsOfDay}] = yield all([
        busyCall(
            axios.get,
            getApiUrl(`/reports/invoices/positions-of-day/product/${productId}/${formats.jsonDate(day)}`, tenantId),
            apiConfig),
        busyCall(
            axios.get,
            getApiUrl(`/reports/rr-invoices/positions-of-day/product/${productId}/${formats.jsonDate(day)}`, tenantId),
            apiConfig)]);

    const currentWindow2 = yield select(o => o.currentWindow.tenantDashboard);
    if (!currentWindow2) {
        return;
    }

    const tenantDashboard2 = {
        ...oldTenantDashboard
    };
    tenantDashboard2.invoiceProductDailyDetails = {invoicePositionsOfTheDay, rrInvoicePositionsOfDay};

    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard: tenantDashboard2}));
}

export function* handleHideInvoiceProductDailyDetails({tenantId}) {
    const oldTenantDashboard = yield select(o => o.currentWindow.tenantDashboard);
    if (!oldTenantDashboard) {
        return;
    }

    const tenantDashboard = {
        ...oldTenantDashboard
    };
    tenantDashboard.invoiceProductDailyDetails = undefined;
    yield put(resetCurrentTenantWindow(tenantId, {tenantDashboard}));
}

export function* handleOpenFeatureFlagsForm({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {}));
    yield invalidateFeatureFlags(tenantId);
    let featureFlags = yield select(o => o.cache[tenantId]?.featureFlags);
    // in case of opening when not yet loaded
    while(!featureFlags) {
        yield delay(500);
        featureFlags = yield select(o => o.cache[tenantId]?.featureFlags);
    }

    yield put(resetCurrentTenantWindow(tenantId, {featureFlagsForm: {data: featureFlags.data}}));
}

export function* handleSaveTenantFeatureFlags({tenantId, data}) {
    const oldForm = yield select(o => o.currentWindow?.featureFlagsForm);
    yield put(resetCurrentTenantWindow(tenantId, {
        featureFlagsForm: {
            ...oldForm,
            saving: true
        }
    }))
    const apiConfig = yield getApiConfig(tenantId);
    yield busyCall(axios.put, getApiUrl('/feature-flags', tenantId), data, apiConfig);


    yield put(resetCurrentWindow({}));
    yield put(openTenantPreview(tenantId));
}

export function* tenantsSaga() {
    yield all([
        takeLatest([INVITE_USER], handleInviteUser),
        takeLatest([ADD_TENANT], busyWrapper(handleAddTenant)),
        takeLatest([ADD_FIRST_TENANT], busyWrapper(handleAddFirstTenant)),
        takeLatest([OPEN_TENANTS_LIST], handleOpenTenantsList),
        takeLatest([OPEN_ADD_TENANT_FORM], handleOpenAddTenantForm),
        takeLatest([OPEN_EDIT_TENANT_FORM], handleOpenEditTenantForm),
        takeLatest([UPDATE_TENANT], handleUpdateTenant),
        takeLatest([RESET_CURRENT_TENANT_WINDOW], handleResetCurrentTenantWindow),
        takeLatest([OPEN_TENANT_PREVIEW], handleOpenTenantPreview),
        takeLatest([SET_AUTH_STATUS], handleSetAuthStatus),
        takeLatest([SHOW_TRANSPORT_PRODUCT_DAILY_DETAILS], handleShowTransportProductDailyDetails),
        takeLatest([HIDE_TRANSPORT_PRODUCT_DAILY_DETAILS], handleHideTransportProductDailyDetails),
        takeLatest([SHOW_INVOICE_PRODUCT_DAILY_DETAILS], handleShowInvoiceProductDailyDetails),
        takeLatest([HIDE_INVOICE_PRODUCT_DAILY_DETAILS], handleHideInvoiceProductDailyDetails),
        takeLatest([OPEN_TENANT_FEATURE_FLAGS_FORM], handleOpenFeatureFlagsForm),
        takeLatest([SAVE_TENANT_FEATURE_FLAGS], handleSaveTenantFeatureFlags)
    ]);
}