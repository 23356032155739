import {all, delay, put, select, takeLatest} from 'redux-saga/effects';
import axios from "../../exios";
import moment from "moment";
import {busyCall} from "./businessSaga";
import {OPEN_RR_INVOICES_LIST, openRrInvoicesList} from "../Actions/openRrInvoicesList";
import {resetCurrentProviderWindow} from "../Actions/resetCurrentProviderWindow";
import {getAxiosConfig, invalidateInvoiceData, invalidateRrInvoiceNumberSummary} from "./tenantsSaga";
import {addProviderEntity, getApiUrl, getProviderUrl, updateProviderEntity} from "./utils";
import {OPEN_ADD_RR_INVOICE_FORM} from "../Actions/openAddRrInvoiceForm";
import {ADD_RR_INVOICE} from "../Actions/addRRInvoice";
import {PRINT_RR_INVOICE} from "../Actions/printRRInvoice";
import {PRINT_RR_INVOICE_COPY} from "../Actions/printRRInvoiceCopy";
import {OPEN_RR_INVOICE_PREVIEW, openRrInvoicePreview} from "../Actions/openRrInvoicePreview";
import {OPEN_EDIT_RR_INVOICE_FORM} from "../Actions/openEditRrInvoiceForm";
import {UPDATE_RR_INVOICE} from "../Actions/updateRrInvoice";
import {downloadFile} from "./downloadFile";
import {DELETE_RR_INVOICE} from "../Actions/deleteRrInvoice";
import {SET_RR_INVOICE_PAID_VALUE} from "../Actions/setRrInvoicePaidValue";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {OPEN_UNPAID_RR_INVOICES_SUMMARY, openUnpaidRrInvoicesSummary} from "../Actions/openUnpaidRrInvoicesSummary";
import {SET_RR_INVOICES_AS_PAID} from "../Actions/setRrInvoicesAsPaid";
import {SEND_RR_INVOICE_BY_EMAIL} from "../Actions/sendRrInvoiceByEmail";
import {blinkNotification} from "../Actions/blinkNotification";
import {invalidateProducts} from "./productListSaga";
import {REQUEST_RR_INVOICE_PROPOSED_NUMBER} from "../Actions/requestRrInvoiceProposedNumber";
import formats from "../../formats";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* handleOpenInvoicesList({tenantId, providerId, year}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {rrInvoicesList: {loading: true, year}}));

    yield invalidateProducts(tenantId);
    yield invalidateInvoiceData(tenantId);

    const config = yield getConfig(tenantId);

    const actualConfig = {
        ...config,
        params: {
            year
        }
    }
    const {data: {items, yearsActive}} = yield busyCall(axios.get, getProviderUrl('/rr-invoices', tenantId, providerId), actualConfig);

    yield put(resetCurrentProviderWindow(tenantId, providerId, {rrInvoicesList: {items, yearsActive, year}}));
}

function* getNewRrInvoiceFormData(tenantId, providerId) {
    yield invalidateRrInvoiceNumberSummary(tenantId);

    let cache = yield select(o => o.cache[tenantId]);
    let invoiceNumberSummary = cache?.rrInvoiceNumberSummary?.data;
    while (!invoiceNumberSummary) {
        yield delay(500);
        cache = yield select(o => o.cache[tenantId]);
        invoiceNumberSummary = cache?.rrInvoiceNumberSummary?.data;
    }

    return {
        formData: {
            date: (invoiceNumberSummary?.lastDate),
            number: '',
            payday: (invoiceNumberSummary?.lastPayday) || '14 dni',
            positions:[]
        },
        proposedNumber: invoiceNumberSummary?.nextNumber
    }
}

function getNewVatInvoiceFormData() {
    return {
        formData: {
            date: moment().startOf('day').toJSON(),
            number: '',
            payday: '',
            positions: []
        },
        proposedNumber: ''
    }
}

function* handleOpenAddInvoiceForm({tenantId, providerId, invoiceType}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId));

    yield invalidateProducts(tenantId);

    const config = yield getConfig(tenantId);
    const {data: dealsToBook} = yield busyCall(
        axios.get,
        getProviderUrl('/rr-invoices/deals-to-book', tenantId, providerId),
        config);

    const {formData, proposedNumber} = invoiceType === 'RR'
        ? yield getNewRrInvoiceFormData(tenantId, providerId)
        : getNewVatInvoiceFormData();

    yield invalidateProducts(tenantId);

    const addRrInvoiceForm = { formData, dealsToBook, proposedNumber, invoiceType };

    yield put(resetCurrentProviderWindow(tenantId, providerId, { addRrInvoiceForm }));
}

function* handleOpenEditInvoiceForm({tenantId, providerId, rrInvoiceId}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId));

    const config = yield getConfig(tenantId);

    yield invalidateProducts(tenantId);
    const [{data: dealsToBook}, {data: invoice}] = yield all([
        busyCall(axios.get, getProviderUrl(`/rr-invoices/deals-to-book?invoiceId=${rrInvoiceId}`, tenantId, providerId), config),
        busyCall(axios.get, getProviderUrl(`/rr-invoices/${rrInvoiceId}`, tenantId, providerId), config)
    ]);

    yield invalidateProducts(tenantId);

    const formData = {
        date: invoice.date,
        number: invoice.number,
        payday: invoice.payday,
        remarks: invoice.remarks,
        positions: invoice.positions
    }

    const editRrInvoiceForm = {
        formData,
        dealsToBook,
        rrInvoiceId,
        invoiceType: invoice.type,
        proposedNumber: invoice.number
    };

    yield put(resetCurrentProviderWindow(tenantId, providerId, {editRrInvoiceForm}));
}

function* handleAddRRInvoice({tenantId, providerId, invoiceType, rrInvoiceData}) {
    yield addProviderEntity(
        tenantId,
        providerId,
        {
            type: invoiceType,
            formData: rrInvoiceData
        },
        'addRrInvoiceForm',
        '/rr-invoices',
        () => openRrInvoicesList(tenantId, providerId)
    );
}

function* handleUpdateInvoiceForm({tenantId, providerId, rrInvoiceId, rrInvoiceData}) {
    yield updateProviderEntity(
        tenantId,
        providerId,
        rrInvoiceData,
        'editRrInvoiceForm',
        `/rr-invoices/${rrInvoiceId}/`,
        () => openRrInvoicesList(tenantId, providerId)
    );
}

function* handleDeleteInvoice({tenantId, providerId, rrInvoiceId}) {
    const config = yield getConfig(tenantId);
    yield busyCall(axios.delete, getProviderUrl(`/rr-invoices/${rrInvoiceId}`, tenantId, providerId), config);
    yield put(openRrInvoicesList(tenantId, providerId));
}

function* handlePrintRRInvoice({tenantId, providerId, rrInvoiceId, invoiceNumber}) {
    yield downloadFile(tenantId, getProviderUrl(`/rr-invoices/${rrInvoiceId}/printouts/original`, tenantId, providerId), `${invoiceNumber}.pdf`);
}

function* handlePrintRRInvoiceCopy({tenantId, providerId, rrInvoiceId, invoiceNumber}) {
    yield downloadFile(tenantId, getProviderUrl(`/rr-invoices/${rrInvoiceId}/printouts/copy`, tenantId, providerId), `${invoiceNumber}-kopia.pdf`);
}

function* handleOpenRRInvoicePreview({tenantId, providerId, rrInvoiceId}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {rrInvoiceDetails: {loading: true, rrInvoiceId}}));

    yield invalidateProducts(tenantId);
    const config = yield getConfig(tenantId);
    const [
        {data: details},
        {data:invoice},
    ] = yield all([
        busyCall(axios.get, getProviderUrl(`/rr-invoices/${rrInvoiceId}/details`, tenantId, providerId), config),
        busyCall(axios.get, getProviderUrl(`/rr-invoices/${rrInvoiceId}`, tenantId, providerId), config)
    ]);

    const rrInvoiceDetails = {
        details,
        invoice,
        rrInvoiceId
    }
    yield put(resetCurrentProviderWindow(tenantId, providerId, {rrInvoiceDetails}));
}

function* handleSetRrInvoicePaidValue({tenantId, providerId, invoiceId, paidValue}) {

    const config = yield getConfig(tenantId);

    yield busyCall(axios.put, getProviderUrl(`/rr-invoices/${invoiceId}/paid-value`, tenantId, providerId), {paidValue}, config);

    const currentWindow = yield select(o => o.currentWindow);

    if (currentWindow.rrInvoicesList) {
        yield put(openRrInvoicesList(tenantId, providerId));
    } else if (currentWindow.rrInvoiceDetails) {
        yield put(openRrInvoicePreview(tenantId, providerId, invoiceId))
    } else if (currentWindow.unpaidRrInvoicesList) {
        yield put(openUnpaidRrInvoicesSummary(tenantId));
    }
}

function* handleOpenUnpaidInvoicesSummary({tenantId}) {
    const oldUnpaidInvoicesList = (yield select(o => o.currentWindow.unpaidRrInvoicesList)) || {};

    const loadingState = {
        unpaidRrInvoicesList: {
            ...oldUnpaidInvoicesList,
            loading: true
        }
    }
    yield put(resetCurrentTenantWindow(tenantId, loadingState));
    const config = yield getConfig(tenantId);

    const {data} = yield busyCall(axios.get, getApiUrl(`/reports/rr-invoices/unpaid`, tenantId), config);

    yield put(resetCurrentTenantWindow(tenantId, {unpaidRrInvoicesList: {data}}));
}

function* handleSetRrInvoicesAsPaid({tenantId, invoicesIds}) {
    const oldUnpaidInvoicesList = (yield select(o => o.currentWindow.unpaidRrInvoicesList)) || {};

    const loadingState = {
        unpaidRrInvoicesList: {
            ...oldUnpaidInvoicesList,
            loading: true
        }
    }
    yield put(resetCurrentTenantWindow(tenantId, loadingState));
    const config = yield getConfig(tenantId);

    yield busyCall(axios.put, getApiUrl(`/rr-invoices`, tenantId), {invoicesIds}, config);

    yield put(openUnpaidRrInvoicesSummary(tenantId));
}

function* handleSendRrInvoiceViaEmail({tenantId, providerId, invoiceId, subject, message}) {
    const config = yield getConfig(tenantId);

    yield busyCall(
        axios.post,
        getProviderUrl(`/rr-invoices/${invoiceId}/email`, tenantId, providerId),
        {subject, message},
        config);

    yield put(blinkNotification("Wiadomość email została wysłana."))
}

function* handleRequestRrInvoiceProposedNumber({tenantId, providerId, date}) {
    try {
        const config = yield getConfig(tenantId);

        const {data: proposedNumber} = yield busyCall(axios.get, getApiUrl(`/reports/rr-invoices/next-number/${formats.jsonDate(date)}`, tenantId), config);

        const oldAddRrInvoiceForm = (yield select(o => o.currentWindow.addRrInvoiceForm));
        if (oldAddRrInvoiceForm) {
            yield put(resetCurrentProviderWindow(tenantId, providerId, {addRrInvoiceForm: {...oldAddRrInvoiceForm, proposedNumber}}));
        }
    } catch {
        console.log('Failed to request invoice proposed number but, it is not a big deal.');
    }
}

export function* rrInvoicesSaga() {
    yield all([
        takeLatest(OPEN_RR_INVOICES_LIST, handleOpenInvoicesList),
        takeLatest(OPEN_ADD_RR_INVOICE_FORM, handleOpenAddInvoiceForm),
        takeLatest(ADD_RR_INVOICE, handleAddRRInvoice),
        takeLatest(OPEN_EDIT_RR_INVOICE_FORM, handleOpenEditInvoiceForm),
        takeLatest(UPDATE_RR_INVOICE, handleUpdateInvoiceForm),
        takeLatest(PRINT_RR_INVOICE, handlePrintRRInvoice),
        takeLatest(PRINT_RR_INVOICE_COPY, handlePrintRRInvoiceCopy),
        takeLatest(OPEN_RR_INVOICE_PREVIEW, handleOpenRRInvoicePreview),
        takeLatest(DELETE_RR_INVOICE, handleDeleteInvoice),
        takeLatest(SET_RR_INVOICE_PAID_VALUE, handleSetRrInvoicePaidValue),
        takeLatest(OPEN_UNPAID_RR_INVOICES_SUMMARY, handleOpenUnpaidInvoicesSummary),
        takeLatest(SET_RR_INVOICES_AS_PAID, handleSetRrInvoicesAsPaid),
        takeLatest(SEND_RR_INVOICE_BY_EMAIL, handleSendRrInvoiceViaEmail),
        takeLatest(REQUEST_RR_INVOICE_PROPOSED_NUMBER, handleRequestRrInvoiceProposedNumber)
    ])
}