import {all, put, takeLatest, select, call} from 'redux-saga/effects';
import {OPEN_PURCHASERS_LIST} from "../Actions/openPurchasersList";
import {getAxiosConfig, handleValidation} from "./tenantsSaga";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {busyCall} from "./businessSaga";
import axios from "../../exios";
import {getApiUrl} from "./utils";
import {OPEN_ADD_PURCHASER_FORM} from "../Actions/openAddPurchaserForm";
import {ADD_PURCHASER} from "../Actions/addPurchaser";
import {OPEN_PURCHASER_PREVIEW, openPurchaserPreview} from "../Actions/openPurchaserPreview";
import {RESET_CURRENT_PURCHASER_WINDOW, resetCurrentPurchaserWindow} from "../Actions/resetCurrentPurchaserWindow";
import {OPEN_EDIT_PURCHASER_FORM} from "../Actions/openEditPurchaserForm";
import {UPDATE_PURCHASER} from "../Actions/updatePurchaser";
import {toNumber} from "lodash";
import {resetCurrentWindow} from "../Actions/resetCurrentWindow";
import {loadCacheEntry} from "../Actions/loadCacheEntry";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* invalidatePurchasersCache(tenantId) {
    yield put(loadCacheEntry(tenantId, 'purchasers', getApiUrl('/purchasers', tenantId)));
}

function* handleOpenPurchasersList({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {purchasers: {}}));

    yield invalidatePurchasersCache(tenantId);
}

function* handleOpenAddPurchaserForm({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {addPurchaserForm: { purchaserData: {}}}))
}

function* handleAddPurchaser({tenantId, purchaserData}) {
    const oldPurchaserForm = yield select(o => o.currentWindow.addPurchaserForm);
    yield put(resetCurrentTenantWindow(tenantId, {addPurchaserForm: {...oldPurchaserForm, saving: true}}));
    const config = yield getConfig(tenantId);

    const response = yield busyCall(axios.post, getApiUrl('/purchasers', tenantId), purchaserData, config);
    const {data: purchaserId, errors} = yield call(handleValidation, response);

    if (!!errors) {
        const oldPurchaserForm = yield select(o => o.currentWindow.addPurchaserForm);
        const addPurchaserForm = {
            ...oldPurchaserForm,
            errors
        }
        yield put(resetCurrentTenantWindow(tenantId, {addPurchaserForm}));
    } else {
        yield put(openPurchaserPreview(tenantId, purchaserId));
    }
}

function* loadDashboardPart(url, tenantId, purchaserId, partName) {
    const config = yield getConfig(tenantId);
    const {data} = yield busyCall(axios.get, getApiUrl(url, tenantId), config);
    const purchaserDashboard = yield select(o => o.currentWindow?.purchaserDashboard);
    const newDashboard = {
        ...purchaserDashboard,
        [partName]: data
    };
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {purchaserDashboard: newDashboard}));
}

function* handleOpenPurchaserPreview({tenantId, purchaserId}) {
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {purchaserDashboard: {}}));

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {purchaserDashboard: {}}));

    yield all([
        loadDashboardPart(`/purchasers/${purchaserId}/invoices/summary`, tenantId, purchaserId, 'invoicesSummary'),
        loadDashboardPart(`/purchasers/${purchaserId}/invoices/provisions-to-book-product-summary`, tenantId, purchaserId, 'provisionsToBookProductSummary'),
        loadDashboardPart(`/purchasers/${purchaserId}/provisions/summary`, tenantId, purchaserId,'provisionSummary'),
        loadDashboardPart(`/purchasers/${purchaserId}/provisions/container-share-summary`, tenantId, purchaserId,'containerSharesSummary')
    ]);
}

function* handleEditPurchaserForm({tenantId, purchaserId}) {
    const config = yield getConfig(tenantId);

    const {data: purchaserData} = yield busyCall(axios.get, getApiUrl(`/purchasers/${purchaserId}`, tenantId), config);

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editPurchaserForm: {purchaserData}}));
}

function* handleUpdatePurchaser({tenantId, purchaserId, purchaserData}) {
    const oldPurchaserForm = yield select(o => o.currentWindow.editPurchaserForm);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editPurchaserForm: {...oldPurchaserForm, saving: true}}));
    const config = yield getConfig(tenantId);

    const response = yield busyCall(axios.put, getApiUrl(`/purchasers/${purchaserId}`, tenantId), purchaserData, config);
    const {errors} = yield call(handleValidation, response);

    if (!!errors) {
        const editPurchaserForm = {
            ...oldPurchaserForm,
            errors
        };
        yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editPurchaserForm}));
    } else {
        // Hack to reload purchaser data
        yield put(resetCurrentWindow({}));
        yield put(openPurchaserPreview(tenantId, purchaserId));
    }
}

function* handleResetCurrentPurchaserWindow({tenantId, purchaserId, state}) {
    const currentWindow = yield select(state => state.currentWindow);
    const purchaserIdNumber = toNumber(purchaserId);
    if (currentWindow?.currentPurchaser?.id === purchaserIdNumber) {
        const modifiedState = {
            ...state,
            currentPurchaser: currentWindow.currentPurchaser
        };
        yield put(resetCurrentTenantWindow(tenantId, modifiedState));
    } else {
        yield invalidatePurchasersCache(tenantId);
        const finalState = {
            ...state,
            currentPurchaser: {id: purchaserIdNumber}
        }
        yield put(resetCurrentTenantWindow(tenantId, finalState));
    }
}

export function* purchasersSaga() {
    yield all([
        takeLatest(OPEN_PURCHASERS_LIST, handleOpenPurchasersList),
        takeLatest(OPEN_ADD_PURCHASER_FORM, handleOpenAddPurchaserForm),
        takeLatest(ADD_PURCHASER, handleAddPurchaser),
        takeLatest(OPEN_PURCHASER_PREVIEW, handleOpenPurchaserPreview),
        takeLatest(OPEN_EDIT_PURCHASER_FORM, handleEditPurchaserForm),
        takeLatest(UPDATE_PURCHASER, handleUpdatePurchaser),
        takeLatest(RESET_CURRENT_PURCHASER_WINDOW, handleResetCurrentPurchaserWindow)
    ])
}