import axios from "../../exios";
import {all, call, takeLatest, put, select} from "redux-saga/effects";
import {OPEN_PROVIDER_LIST, openProviderList} from '../Actions/openProviderList';
import {OPEN_PROVIDER_PREVIEW, openProviderPreview} from "../Actions/openProviderPreview";
import {RESET_CURRENT_PROVIDER_WINDOW, resetCurrentProviderWindow} from "../Actions/resetCurrentProviderWindow";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {getAxiosConfig, handleValidation} from "./tenantsSaga";
import {busyWrapper, busyCall} from "./businessSaga";
import {OPEN_ADD_PROVIDER_FORM} from "../Actions/openAddProviderForm";
import {ADD_PROVIDER} from "../Actions/addProvider";
import {addTenantEntity, getApiUrl, getProviderUrl} from "./utils";
import {OPEN_EDIT_PROVIDER_FORM} from "../Actions/openEditProviderForm";
import {UPDATE_PROVIDER} from "../Actions/updateProvider";
import {toNumber} from "lodash";
import {resetCurrentWindow} from "../Actions/resetCurrentWindow";
import {SET_PROVIDER_DELETED} from "../Actions/setProviderDeleted";
import {loadCacheEntry} from "../Actions/loadCacheEntry";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* invalidateProviders(tenantId) {
    yield put(loadCacheEntry(tenantId, 'providers', getApiUrl('/providers', tenantId)));
}

function* handleOpenProvidersList({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {providers: { }}));
    yield invalidateProviders(tenantId);
}

function* handleOpenAddProvidersForm({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {addProviderForm: {providerData: {name: '', isVatPayer: false}}}));
}

function* handleAddProviders({tenantId, providerData}) {
    yield addTenantEntity(
        tenantId,
        providerData,
        'addProviderForm',
        '/providers',
        id => openProviderPreview(tenantId, id));
}

function* handleOpenProviderPreview({tenantId, providerId}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {providerDashboard: {}}));
    const config = yield getConfig(tenantId);
    yield all([
        loadDashboardPart('/rr-invoices/deals-to-book-product-summary', config, tenantId, providerId, 'dealsToBookProductSummary'),
        loadDashboardPart('/rr-invoices/summary', config, tenantId, providerId, 'rrInvoicesSummary'),
        loadDashboardPart('/deals/summary', config, tenantId, providerId, 'dealsSummary'),
        loadDashboardPart('/deals/container-share-summary', config, tenantId, providerId, 'containerSharesSummary'),
    ]);
}

function* loadDashboardPart(url, config, tenantId, providerId, partName) {
    const {data} = yield busyCall(axios.get, getProviderUrl(url, tenantId, providerId), config);
    const providerDashboard = yield select(o => o.currentWindow?.providerDashboard);
    const newDashboard = {
        ...providerDashboard,
        [partName]: data
    };
    yield put(resetCurrentProviderWindow(tenantId, providerId, {providerDashboard: newDashboard}));
}

function* handleOpenEditProviderForm({tenantId, providerId}) {
    const config = yield getConfig(tenantId);
    const {data: providerData} = yield busyCall(axios.get, getApiUrl(`/providers/${providerId}`, tenantId), config);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {editProviderForm: {providerData}}));
}

function* handleUpdateProvider({tenantId, providerId, providerData}) {
    const oldProviderForm = yield select(o => o.currentWindow.editProviderForm);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {editProviderForm: {...oldProviderForm, saving: true}}));

    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.put, getApiUrl(`/providers/${providerId}`, tenantId), providerData, config);
    const {errors} = yield call(handleValidation, response);

    if (!!errors) {
        const editProviderForm = {
            ...oldProviderForm,
            errors
        };
        yield put(resetCurrentProviderWindow(tenantId, providerId, {editProviderForm}))
    } else {
        // Hack to reload provider data
        yield put(resetCurrentWindow({}));
        yield put(openProviderPreview(tenantId, providerId));
    }
}

function* handleSetProviderDeleted ({tenantId, providerId, isDeleted}) {
    const config = yield getConfig(tenantId);
    yield busyCall(axios.put, getApiUrl(`/providers/${providerId}/is-deleted`, tenantId), {isDeleted}, config);
    yield put(openProviderList(tenantId, false));
}

function* handleResetCurrentProviderWindow({tenantId, providerId, state}) {
    const currentWindow = yield select(state => state.currentWindow);
    const providerIdNumber = toNumber(providerId);
    if (currentWindow?.currentProvider?.id === providerIdNumber) {
        const modifiedState = {
            ...state,
            currentProvider: currentWindow.currentProvider
        };
        yield put(resetCurrentTenantWindow(tenantId, modifiedState));
    } else {
        yield invalidateProviders(tenantId);
        const finalState = {
            ...state,
            currentProvider: { id: providerIdNumber }
        }

        yield put(resetCurrentTenantWindow(tenantId, finalState));
    }
}

export function* providerSaga() {
    yield all([
        takeLatest(OPEN_PROVIDER_LIST, handleOpenProvidersList),
        takeLatest(OPEN_ADD_PROVIDER_FORM, handleOpenAddProvidersForm),
        takeLatest(ADD_PROVIDER, busyWrapper(handleAddProviders)),
        takeLatest(OPEN_PROVIDER_PREVIEW, handleOpenProviderPreview),
        takeLatest(OPEN_EDIT_PROVIDER_FORM, handleOpenEditProviderForm),
        takeLatest(UPDATE_PROVIDER, handleUpdateProvider),
        takeLatest(SET_PROVIDER_DELETED, handleSetProviderDeleted),
        takeLatest(RESET_CURRENT_PROVIDER_WINDOW, handleResetCurrentProviderWindow)
    ]);
}