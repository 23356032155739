import {all, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import {addTenantEntity, getApiUrl, updateTenantEntity} from "./utils";
import {getAxiosConfig} from "./tenantsSaga";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {busyCall} from "./businessSaga";
import {OPEN_CONTAINER_LIST, openContainerList} from "../Actions/openContainerList";
import {OPEN_ADD_CONTAINER_FORM} from "../Actions/openAddContainerForm";
import {ADD_CONTAINER} from "../Actions/addContainer";
import {OPEN_EDIT_CONTAINER_FORM} from "../Actions/openEditContainerForm";
import {UPDATE_CONTAINER} from "../Actions/updateContainer";
import {loadCacheEntry} from "../Actions/loadCacheEntry";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

export function* invalidateContainers(tenantId) {
    yield put(loadCacheEntry(tenantId, 'containers', getApiUrl('/containers/', tenantId)));
}

function* handleOpenContainersList({tenantId}) {
    yield invalidateContainers(tenantId);
    yield put(resetCurrentTenantWindow(tenantId, {containerList: {}}));
}

function* handleOpenAddContainerForm({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {addContainerForm: { containerData: {name: ''} }}));
}

function* handleAddContainer({tenantId, containerData}) {
    yield addTenantEntity(
        tenantId,
        containerData,
        'addContainerForm',
        '/containers/',
        () => openContainerList(tenantId));
}

function* handleOpenEditContainerForm({tenantId, containerId}) {
    yield put(resetCurrentTenantWindow(tenantId, {}));
    const config = yield getConfig(tenantId);
    const {data: containerData} = yield busyCall(axios.get, getApiUrl(`/containers/${containerId}`, tenantId), config);
    yield put(resetCurrentTenantWindow(tenantId, {editContainerForm: {containerData, containerId}}));
}

function* handleUpdateContainer({tenantId, containerId, containerData}) {
    yield updateTenantEntity(
        tenantId,
        containerData,
        'editContainerForm',
        `/containers/${containerId}`,
        () => openContainerList(tenantId));
}

export function* containersSaga() {
    yield all([
        takeLatest(OPEN_CONTAINER_LIST, handleOpenContainersList),
        takeLatest(OPEN_ADD_CONTAINER_FORM, handleOpenAddContainerForm),
        takeLatest(ADD_CONTAINER, handleAddContainer),
        takeLatest(OPEN_EDIT_CONTAINER_FORM, handleOpenEditContainerForm),
        takeLatest(UPDATE_CONTAINER, handleUpdateContainer)
    ])
}